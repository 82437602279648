import { Service } from 'common/services'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { ELocales } from './locales'

import { Locales } from './sources'

const language = Service.Localization.getLocale()

i18n.use(initReactI18next).init({
  resources: {
    [ELocales.en]: { translations: Locales.en },
    [ELocales.uk]: { translations: Locales.uk },
  },
  fallbackLng: ELocales.uk,
  lng: language,
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
})

export { i18n }
