import { Png } from "common/assets";
import { EColors } from "common/styles";
import { FONT } from "common/utils";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const Section = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  /* padding-top: 34px; */
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.63) 0%, rgba(0, 0, 0, 0.63) 100%), url(${Png.BgNew});
  /* background-attachment: fixed; */
  background-size: cover; 
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  overflow: hidden;
  @media screen and (min-width: 767px) {
    background-attachment: fixed;
  }
`;

export const Title = styled(Styled.H3)`
  color: ${EColors.white};
  width: 100%;
  max-width: 1076px;
  text-align: center;
  padding: 0 72px;
   @media screen and (max-width: 550px) {
    padding: 0 16px;
  }
`