
import {
  Container,
  ContantContainer,
  Noise1,
  Noise2,
  Noise4,
} from './styled'

export const Noise = () => {
  return (
    <>
      <Container>
        <ContantContainer>
          <Noise1 />
          <Noise2 />
          <Noise4 />
        </ContantContainer>
      </Container>
    </>
  )
}
