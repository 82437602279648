import { Config } from "common/config";
import { useResolution } from "common/hooks";
import { Button } from "components/buttons";
import { useTranslation } from "react-i18next";
import { Component } from "./components";
import {
  ButtonContainer,
  Container,
  ContantContainer,
  MottoInfo,
  MottoInfoWrapper,
} from "./styled";

export const Main = () => {
  const { t } = useTranslation();
  const { isMinScreen1000 } = useResolution();

  const onBottomClick = () => {
    window.location.href = `#${Config.Section.bookTable}`;
  };

  return (
    <>
      <Container>
        <ContantContainer>
          <Component.Motto />
          <Component.Franchise />
          <MottoInfoWrapper>
            <MottoInfo>{t("motto.info")}</MottoInfo>
            {isMinScreen1000 && <Component.LoadApp />}
            <ButtonContainer>
              <Button.Position
                position="bottom"
                size={isMinScreen1000 ? "128px" : "100px"}
                onClick={onBottomClick}
              />
              {!isMinScreen1000 && <Component.LoadApp />}
            </ButtonContainer>
          </MottoInfoWrapper>
        </ContantContainer>
      </Container>
    </>
  );
};
