import { Svg } from 'common/assets'
import { useTranslation } from 'react-i18next'
import { Container, Icon, Text, FlexWrapperUpButton } from './styled'

export const UpButton = () => {
  const { t } = useTranslation()

  const onUpButton = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      <FlexWrapperUpButton>
        <Container onClick={onUpButton}>
          <Icon src={Svg.ArrowUp} />
          <Text>{t('up')}</Text>
        </Container>
      </FlexWrapperUpButton>
    </>
  )
}
