export enum EColors {
  black = '#000000',
  white = '#ffffff',

  neutral_100 = '#F8F8F8',
  neutral_200 = '#F4F4F4',
  neutral_300 = '#DFDFDF',
  neutral_400 = '#CACACA',
  neutral_500 = '#B5B5B5',
  neutral_600 = '#A0A0A0',
  neutral_700 = '#8B8B8B',
  neutral_800 = '#222222',

  primary_100 = '#AEB8B0',
  primary_200 = '#7F9183',
  primary_300 = '#687D6C',
  primary_400 = '#516A56',
  primary_500 = '#3C563E',
  primary_600 = '#29432D',
  primary_700 = '#17301A',

  bg_dark = '#FBF3E6',
  bg_light = '#FCF9F0',

  filter_white = 'invert(100%) sepia(0%) saturate(0%) hue-rotate(53deg) brightness(106%) contrast(101%)',
  filter_black = 'invert(0%) sepia(92%) saturate(31%) hue-rotate(157deg) brightness(92%) contrast(107%)',

  filter_primary_100 = 'invert(78%) sepia(7%) saturate(241%) hue-rotate(80deg) brightness(93%) contrast(90%)',

  filter_primary_500 = 'invert(30%) sepia(29%) saturate(434%) hue-rotate(74deg) brightness(91%) contrast(92%)',
  filter_primary_600 = 'invert(18%) sepia(36%) saturate(551%) hue-rotate(78deg) brightness(96%) contrast(86%)',

  filter_neutral_600 = 'invert(70%) sepia(0%) saturate(45%) hue-rotate(257deg) brightness(91%) contrast(90%)',
}
