import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  z-index: 11;

  position: absolute;
`

export const LineContainer = styled.div`
  width: 100%;
  top: 0px;
  left: 85px;
`
