import { EColors, EFont } from 'common/styles';
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 1000px) {
    order: 2;
    margin-top: 36px;
    align-items: center;
  }
`

export const ItemsList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 1000px) {
    align-items: center;
  }
`
export const LinkItem = styled.li`
  position: relative;
  &:after {
    position: absolute;
    display: block;
    content: "";
    width: 0%;
    height: 1px;
    background: ${EColors.primary_500};
    position: absolute;
    bottom: -4px;
    left: 0;
    transition: all 0.2s ease;
  }
  &:hover:after {
    width: 100%;
    background: ${EColors.primary_500};
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
export const Link = styled.a`
  text-decoration: none;
  font-family: ${EFont.Montserrat};
  font-weight: 500;
  font-size: 16px;
  line-height: 132%;
  text-transform: uppercase;
  font-feature-settings: "case" on;
  color: ${EColors.primary_500};
  white-space:nowrap;
  transition: all 0.2s ease 0s;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 18.48px;
  }
  &:hover {
    color: ${EColors.primary_500};
  }
`;
