import { EColors } from 'common/styles'
import { FONT } from 'common/utils'
import styled from 'styled-components'

export const Container = styled.button`
  position: absolute;
  top: 42px;
  background-color: transparent;
  border: none;

  display: flex;
  gap: 11.5px;
  align-items: center;

  transform: rotateZ(90deg) rotateX(180deg) rotateY(180deg);

  flex-direction: row-reverse;

  cursor: pointer;
`

export const Text = styled.span`
  ${FONT({ weight: '500', size: '16px', color: EColors.primary_500 })}
`

export const Icon = styled.img`
  transform: rotateZ(90deg);
  width: 14.58px;
  height: 21.29px;
  fill:${EColors.primary_500};
`
export const FlexWrapperUpButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;
