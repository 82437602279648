import { Svg } from 'common/assets'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Dragonfly,
  MottoText,
  MottoWrapper,
  TextAbsoluteWrapper,
  TextRelativeWrapper,
} from './styled'

export const Motto = () => {
  const { t } = useTranslation()
  const language = localStorage.getItem("Localization") ? localStorage.getItem("Localization") :'uk'

  return (
    <>
      <Container>
        <MottoWrapper style={{ maxWidth: language==='uk'?'966px':'1148px'}}>
          <TextAbsoluteWrapper>
            <TextRelativeWrapper>
              {/* Motto text */}
              <MottoText>{t('motto')}</MottoText>
              {/* Dragonfly */}
              <Dragonfly src={Svg.Dragonfly} />
            </TextRelativeWrapper>
          </TextAbsoluteWrapper>
        </MottoWrapper>
      </Container>
    </>
  )
}
