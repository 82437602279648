import { Modal } from 'modules/modals'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { EBookStatus } from './types'

type BookProps = {
  children: ReactNode
}

type BookContextProps = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  status: EBookStatus
  setStatus: Dispatch<SetStateAction<EBookStatus>>
}

export const BookContext = createContext<BookContextProps>({
  isOpen: false,
  setIsOpen: () => {},
  status: EBookStatus.book,
  setStatus: () => {},
})

export const BookProvider = ({ children }: BookProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [status, setStatus] = useState<EBookStatus>(EBookStatus.book)

  const value = {
    isOpen,
    setIsOpen,
    status,
    setStatus,
  }
  const urlParams = new URLSearchParams(window.location.search)
  const myParam = urlParams.get('modal')

  useEffect(() => {
    if (myParam === 'reservation') {
      setIsOpen(true)
    }
  }, [])

  useEffect(() => {
    setStatus(EBookStatus.book)
  }, [isOpen])

  return (
    <>
      <BookContext.Provider value={value}>
        {children}
        {isOpen && <Modal.Book />}
      </BookContext.Provider>
    </>
  )
}
