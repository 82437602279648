import { diffYears } from 'common/helpers'
import { EColors } from 'common/styles'
import { IntervalNumber } from 'components/IntervalNumber'
import { RowInfo } from 'components/RowInfo'
import { useTranslation } from 'react-i18next'
import { Container, StyledNumber, Title } from './styled'

const FOUNDED_DATE = [1995, 8, 9]
const statistics = [
  { numbers:[140,145,180,208,156,178,185,201,210], title: 'guests', info: 'guests.info' },
  { numbers:[0,0,0,0,0,1], title: 'bottles', info: 'bottles.info' },
  { number: diffYears(FOUNDED_DATE) , title: 'years', info: 'years.info' },
]

export const Statistics = () => {
  const { t } = useTranslation()

  return (
    <>
      <Container>
        {statistics.map((statistic, index) => (
          <RowInfo
            key={index}
            Title={
              <>
                <StyledNumber>
                  {statistic.numbers && (<IntervalNumber numbers={statistic.numbers}  />)}
                  {statistic.number}
                </StyledNumber>
                <Title color={EColors.primary_500}>{t(statistic.title)}</Title>
              </>
            }
            Info={<>{t(statistic.info)}</>}
          />
        ))}
      </Container>
    </>
  )
}
