import { LandingContext } from 'common/contexts/Landing/Landing'
import { useIntersection, useResolution } from 'common/hooks'
import { Styled } from 'components/Styled'
import { baseURL } from 'models'
import { GeneralMenu } from 'modules/GeneralMenu'
import { useContext, useRef } from 'react'
import { Fade } from 'react-awesome-reveal'
import { Trans, useTranslation } from 'react-i18next'
import {
  AboutContainer,
  BookContainer,
  Container,
  ContantContainer,
  LeftImage,
  RightImage,
} from './styled'



export const General = () => {
  const { t } = useTranslation()
  const { landing } = useContext(LandingContext);
  const language = localStorage.getItem("Localization") ?? "uk";
  const { isMinScreen1000 } = useResolution()
  const ref = useRef(null)
  const isInteract = useIntersection({ element: ref })

  return (
    <>
      <Container>
        <Fade direction="up" triggerOnce delay={500} cascade style={{ opacity: isInteract ? "1" : "0", }}>
          <Styled.H2 ref={ref}>{t('little.about.us')}</Styled.H2>

          <ContantContainer>
            {/* left */}
            <BookContainer>
              <LeftImage
                src={`${baseURL}/${landing?.headerPhoto}`}
                alt="image restaurant"
              />
              {!isMinScreen1000 && (
                <Styled.FlexWrapper direction="column" gap={24}>
                  <Styled.PBody1>
                    {language === "uk" ? landing?.description[0].value : landing?.description[1].value}
                  </Styled.PBody1>
                </Styled.FlexWrapper>
              )}
              
            </BookContainer>

            {/* right */}
            <AboutContainer>
              <RightImage
                src={`${baseURL}/${landing?.mainImage}`}
                alt="image restaurant"
              />

              {isMinScreen1000 && (
                <Styled.FlexWrapper direction="column" gap={24}>
                  <Styled.PBody1>
                    {language === "uk" ? landing?.description[0].value : landing?.description[1].value}
                  </Styled.PBody1>
                </Styled.FlexWrapper>
              )}
              <GeneralMenu
                hideOurMenu
                style={{ flexDirection: 'row-reverse' }}
              />
            </AboutContainer>
          </ContantContainer>
        </Fade>
      </Container>
    </>
  )
}
