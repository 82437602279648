import { BookContext, EBookStatus } from 'common/contexts/Book'
import { useContext } from 'react'
import { Component } from './components'
import { Container } from './styled'

export const Book = () => {
  const { status } = useContext(BookContext)

  return (
    <>
      <Container>
        <Component.Header />
        {status === EBookStatus.book && <Component.Form />}
        {status === EBookStatus.success && <Component.Done />}
        {status === EBookStatus.error && <Component.Error />}
        {status === EBookStatus.busy && <Component.Busy />}
      </Container>
    </>
  )
}
