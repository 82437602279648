import { EColors } from 'common/styles'
import { TPallete } from './types'

type TKeys = 'standart' | 'white'

export const ButtonPositionPallete: Record<TKeys, TPallete> = {
  standart: {
    theme: EColors.primary_500,
    filter_theme: EColors.filter_primary_500,
    active_arrow_color: EColors.filter_white,
    disabled: EColors.primary_100,
    filter_disabled: EColors.filter_primary_100,
  },
  white: {
    theme: EColors.white,
    filter_theme: EColors.filter_white,
    active_arrow_color: EColors.filter_black,
    disabled: EColors.white,
    filter_disabled: EColors.filter_white,
  },
}
