import { Svg } from 'common/assets'
import { Container, LineContainer } from './styled'

export const Background = () => {
  return (
    <>
      <Container>
        <LineContainer>
          <embed src={Svg.MenuLine} width="100%" />
        </LineContainer>
      </Container>
    </>
  )
}
