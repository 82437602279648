import { useIntersection, useResolution } from 'common/hooks'
import { EColors } from 'common/styles'
import { Styled } from 'components/Styled'
import { useRef } from 'react'
import { Fade } from 'react-awesome-reveal'
import { ContentWarpper, InfoWrapper, TitleWrapper } from './styled'
import { RowInfoProps } from './types'

export const RowInfo = ({ Info, Title }: RowInfoProps) => {
  const { isMinScreen1000 } = useResolution()
  const ref = useRef(null)
  const isInteract = useIntersection({ element: ref })

  return (
    <>
      <Fade
        cascade
        triggerOnce
        direction="up"
        delay={800}
        duration={500}
        damping={0.5}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: isMinScreen1000 ? "30px" : "20px",
          opacity: isInteract ? "1" : "0",
        }}
      >
        <ContentWarpper>
          {/* Left */}
          <TitleWrapper>{Title}</TitleWrapper>

          {/* Right */}
          <InfoWrapper>
            <Styled.PBody2SB color={EColors.primary_200}>
              {Info}
            </Styled.PBody2SB>
          </InfoWrapper>
        </ContentWarpper>
        <Styled.Hr />
      </Fade>
    </>
  )
}
