import { Svg } from 'common/assets'
import { BookContext } from 'common/contexts/Book'
import { Styled } from 'components/Styled'
import { useContext, useEffect, useState } from 'react'
import { MenuButton } from './styled'

export const RightMenu = () => {
  const { isOpen, setIsOpen } = useContext(BookContext)

  

  const MenuIcon = Svg.Cross 

  const handleClose = () => {
      window.location.href = "/";
    setTimeout(() => {
      setIsOpen(false);
    }, 500);
  }

  return (
    <>
      <Styled.FlexWrapper gap={30}>
        {/* Menu button */}
        <MenuButton onClick={handleClose}>
          <img src={MenuIcon} />
        </MenuButton>
      </Styled.FlexWrapper>
    </>
  )
}
