import { EColors } from 'common/styles'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  background-color: ${EColors.bg_light};
  top: 0;
  left: 0;
  overflow-y: auto;

  @media screen and (max-width: 550px) {
    padding: 0px 16px;
  }
`
