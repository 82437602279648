import { useIntersection } from 'common/hooks'
import { useRef } from 'react'
import { Container, ContantContainer, Noise1, Noise2 } from './styled'

export const Noise = () => {
  return (
    <>
      <Container>
        <ContantContainer>
          <Noise1 />
          <Noise2 />
        </ContantContainer>
      </Container>
    </>
  )
}
