import { useEffect, useState } from 'react'
import { Error, Input, InputWrapper } from './styled'
import { StadartProps } from './types'

const DEFAULT_WIDTH = '100%'
const DEFAULT_HEIGHT = '33px'

export const Standart = ({
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  type = 'text',
  placeholder,
  isInvalid,
  errorText,
  ...props
}: StadartProps) => {
  const [showType, setShowType] = useState(false)

  useEffect(() => {
    !!props.value && setShowType(true)
  }, [props.value])

  const isTypeText = type === 'text'
  const currentType = showType ? type : isTypeText ? 'text' : 'number'

  const isDate = type === 'date'
  const isTime = type === 'time'

  const dateTimePlaceholder = showType ? '' : placeholder
  const currentPlaceholder =
    isDate || isTime ? dateTimePlaceholder : placeholder

  return (
    <>
      <InputWrapper type={currentType}>
        <Input
          {...props}
          onFocus={() => setShowType(true)}
          onTouchStart={() => setShowType(true)}
          width={width}
          height={height}
          placeholder={currentPlaceholder}
          type={currentType}
        />
        {isInvalid && errorText && <Error>{errorText}</Error>}
      </InputWrapper>
    </>
  )
}
