import { EColors } from 'common/styles'
import { Styled } from 'components/Styled'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  
  max-width: 1440px;
  padding: 0px 72px;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  
  @media screen and (max-width: 787px) {
    padding: 0px 16px;
  }
`

export const Title = styled(Styled.H4)`
  @media screen and (max-width: 787px) {
    font-size: 16px;
    line-height: 20px;
  }
`
export const StyledNumber = styled(Styled.H2)`
color:${EColors.primary_500};
line-height:initial;
`
