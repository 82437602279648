import { Config } from 'common/config'
import { EColors } from 'common/styles'
import { Button } from 'components/buttons'
import { Styled } from 'components/Styled'
import { useTranslation } from 'react-i18next'

export const Footer = () => {
  const { t } = useTranslation()

  return (
    <>
      <Styled.FlexWrapper direction="column" gap={28}>
        {/* Phone */}
        <Styled.FlexWrapper gap={20}>
          <Styled.PBody2>{t('phone')}:</Styled.PBody2>
          <Styled.ButtonText color={EColors.primary_500}>
            {Config.General.phone}
          </Styled.ButtonText>
        </Styled.FlexWrapper>

        {/* Social media */}
        <Styled.FlexWrapper gap={20}>
          <Styled.PBody2>{t('social.media')}:</Styled.PBody2>
          <Button.SocialMedia gap={16} />
        </Styled.FlexWrapper>
      </Styled.FlexWrapper>
    </>
  )
}
