import { Svg } from 'common/assets'
import { useResolution } from 'common/hooks'
import { CopyRight } from 'components/CopyRight'
import { FlexWrapperBrand, StyledCopyRight } from './styled'


export const BrandEntities = () => {
 
  return (
    <>
      <FlexWrapperBrand>
        <img src={Svg.Logo} />
        <StyledCopyRight />
      </FlexWrapperBrand>
    </>
  )
}
