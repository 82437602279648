import { Png } from 'common/assets'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  padding-left: 70px;
  padding-right: 72px;
  align-self: center;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    padding: 0px 16px;
  }
`

export const Image1 = styled.img.attrs({ src: Png.Enjoy1 })`
  width: 100%;
  max-width: 633px;
`

export const SecondContainer = styled.div`
  width: 100%;
  margin-top: 58px;
  display: flex;

  @media screen and (max-width: 900px) {
    margin-top: 25px;

    align-items: center;
    justify-content: center;
  }
`

export const InfoContainer = styled.div`
  margin-left: 135px;
  margin-right: 83px;
  max-width: 100%;
  width: 636px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 48px;

  @media screen and (max-width: 900px) {
    padding: 0px 16px;
    margin: 0px;
    gap: 24px;
    justify-content: center;
    align-items: center;
  }
`

export const Image2Container = styled.div`
  max-width: 196px;
  width: 100%;
  margin-top: 17px;

  @media screen and (max-width: 1150px) {
    display: none;
  }
`

export const Image2 = styled.img.attrs({ src: Png.Enjoy2 })`
  width: 100%;
`

export const Image3Container = styled.div`
  max-width: 247px;
  width: 100%;
  margin-top: 67px;

  @media screen and (max-width: 1150px) {
    display: none;
  }
`

export const Image3 = styled.img.attrs({ src: Png.Enjoy3 })`
  width: 100%;
`

export const ThirdContainer = styled.div`
  padding-left: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-top: 67px;

  @media screen and (max-width: 1100px) {
    width: 120%;
    margin-top: 43px;
    justify-content: center;
    padding-right: 66px;
  }
`

export const Image4 = styled.img.attrs({ src: Png.Enjoy4 })`
  width: 100%;
  max-width: 636px;

  @media screen and (max-width: 1100px) {
    width: 227px;
  }
`

export const Image5 = styled.img.attrs({ src: Png.Enjoy5 })`
  width: 100%;
  max-width: 196px;
  margin-left: 134px;
  @media screen and (max-width: 1100px) {
    margin-left: 33px;
    width: 101px;
  }
`

export const Image6 = styled.img.attrs({ src: Png.Enjoy6 })`
  width: 100%;
  max-width: 306px;
  margin-left: 24px;
  margin-top: calc(325px - 64px);

  @media screen and (max-width: 1100px) {
    margin-left: -40px;
    width: 134px;
    margin-top: 142px;
  }
`
