import { Svg } from 'common/assets'
import { Config } from 'common/config'
import { Styled } from 'components/Styled'
import { SocialMediaButton } from './styled'

type SocialMediaProps = {
  gap?: number
}

export const SocialMedia = ({ gap = 24 }: SocialMediaProps) => {
  const onInstagramClick = () => {
    window.location.href = Config.General.instagram
  }

  const onFacebookClick = () => {
    window.location.href = Config.General.facebook
  }

  return (
    <>
      <Styled.FlexWrapper gap={gap}>
        {/* Instagram */}
        <SocialMediaButton onClick={onInstagramClick}>
          <img src={Svg.Instagram} />
        </SocialMediaButton>

        {/* Facebook */}
        <SocialMediaButton onClick={onFacebookClick}>
          <img src={Svg.Facebook} />
        </SocialMediaButton>
      </Styled.FlexWrapper>
    </>
  )
}
