import { Png, Svg } from 'common/assets'
import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;

`

export const ContantContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export const Noise1 = styled.img.attrs({ src: Png.Noise4 })`
  position: absolute;
  top: 25px;
  left: -9.5px;
   @media screen and (max-width: 1000px) {
    top: 0;
  }
`

export const Noise2 = styled.img.attrs({ src: Png.Noise1 })`
  position: absolute;
  top: 25px;
  right: -0.5px;
   @media screen and (max-width: 1000px) {
    display: none;
  }

`
