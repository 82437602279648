import { ELocales, i18n } from 'common/locales'

export class Localization {
  static KEY = 'Localization'

  public static setLocale(locale: ELocales) {
    localStorage.setItem(this.KEY, locale)
    i18n.changeLanguage(locale)
  }

  public static getLocale = (): ELocales => {
    return (localStorage.getItem(this.KEY) || ELocales.uk) as ELocales
  }
}
