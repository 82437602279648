import { Config } from 'common/config'
import { BookContext } from 'common/contexts/Book'
import { Button } from 'components/buttons'
import { EllipseButtonPallets } from 'components/buttons/Ellipse'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Section, Title} from './styled'

export const BookTable = () => {
  const { t } = useTranslation()

  const { setIsOpen } = useContext(BookContext)

  const onBookTable = () => {
    setIsOpen(true)
  }
  return (
    <>
      <Section id={Config.Section.bookTable}>
        <Title>{t('book.title')}</Title>
        <Button.Ellipse
          colorPallete={EllipseButtonPallets.white}
          onClick={onBookTable}>
          {t('book.table')}
        </Button.Ellipse>
      </Section>
    </>
  )
}
