import { EColors } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: calc(100vh - 136px);
  padding:90px 72px 52px;
  background-color: ${EColors.bg_light};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 764px;
  padding-top: 90px;
  min-height: 800px;
  @media screen and (max-width: 999px) {
    padding: 72px 16px 0;
  }

  @media screen and (max-width: 550px) {
    height: calc(100vh - 80px);
    
  }
  @media screen and (min-width: 1000px) and (max-width: 1350px) {
    min-height: 800px;
  }
`;

export const MottoInfoWrapper = styled.div`
  padding: 0px 72px;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: 999px) {
    padding: 0px;
    margin-top: 60px;
  }

  @media screen and (min-width: 1000px) {
    position: relative;
    align-items: flex-end;
    opacity: 0;
    transform: translateY(-100%);
    animation: appearence 0.5s linear 0.3s forwards;
  }
  @media screen and (min-width: 1000px) and (max-width: 1350px) {
    gap: 24px;
    margin-top: 0;
  }
  @media screen and (min-width: 1351px) {
    gap: 50px;
    padding:0;
  }
  @media screen and (max-width: 700px) {
    margin-top: 24px;
  }
  @keyframes appearence {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const MottoInfo = styled(Styled.PBody1)`
  width: 100%;
  max-width: 526px;
  color: ${EColors.neutral_800};
`;

export const ContantContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  opacity: 0;
  transition: all 0.4s linear;
  animation: appearance 3s linear 0.5s forwards;

  @media screen and (max-width: 999px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 60px;
    margin-top: 60px;
  }

  @media screen and (min-width: 1000px) {
    position: absolute;
    bottom: -30px;
    width: 128px;
    height: 128px;
    left: 50%;
    transform: translate(-50%);
  }
  @media screen and (min-width: 1000px) and (max-width: 1350px) {
    bottom: -150px;
  }
  @keyframes appearance {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;
