import { EColors } from 'common/styles'
import { Styled } from 'components/Styled'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 168px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 999px) {
    height: 76px;
  }
`

export const MottoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0;
  transform: translateY(-100%);
  animation: appearence 0.4s linear 0.1s forwards;
  @keyframes appearence {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`
export const MottoText = styled(Styled.H1)`
  color: ${EColors.primary_500};
`

export const Dragonfly = styled.img`
  position: absolute;
  top: -37px;
  left: -35px;
  z-index: -1;

  @media screen and (max-width: 999px) {
    top: -50px;
    left: -10px;
    width: 57px;
  }
`

export const TextAbsoluteWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TextRelativeWrapper = styled.div`
  position: relative;
`
