import { Svg } from 'common/assets'
import { BookContext, EBookStatus } from 'common/contexts/Book'
import { EColors } from 'common/styles'
import { Button } from 'components/buttons'
import { Styled } from 'components/Styled'
import { useContext, useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import { Container, FormContainer, StatusImage, TextInfo } from './styled'

export const Error = () => {
  const { t } = useTranslation()
  const { setStatus } = useContext(BookContext)

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true)
    }, 0)
  }, [isVisible])

  const onBackClick = () => {
    setStatus(EBookStatus.book)
  }
  return (
    <>
      <Container>
        <FormContainer style={{ opacity: isVisible ? 1 : 0 }}>
          <Fade triggerOnce>
            <StatusImage src={Svg.Error} />

            <Styled.H2 color={EColors.primary_500} align="center">
              {t('something.go.wrong')}
            </Styled.H2>

            <TextInfo>{t('book.error.text')}</TextInfo>

            <Button.Ellipse onClick={onBackClick}>
              {t('try.again')}
            </Button.Ellipse>
          </Fade>
        </FormContainer>
      </Container>
    </>
  )
}
