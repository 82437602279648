import { Svg } from 'common/assets'
import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
`

export const ContantContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export const Noise1 = styled.img.attrs({ src: Svg.Noise1 })`
  position: absolute;
  top: 64px;
  left: 13px;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`

export const Noise2 = styled.img.attrs({ src: Svg.Noise2 })`
  position: absolute;
  top: 678px;
  right: 0px;
  @media screen and (max-width: 1000px) {
  top:15% ;
  right: 9px;
  }
`

export const Noise4 = styled.img.attrs({ src: Svg.Noise4 })`
  position: absolute;
  bottom:-1% ;
  right: 9px;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`

