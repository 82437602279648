import { MutableRefObject, RefObject, useEffect, useState } from 'react'

type useInViewPort = {
  target?: RefObject<HTMLElement>
}

export const useInViewPort = ({ target }: useInViewPort) => {
  const [isInHorizontalViewPort, setIsInHorizontalViewPort] = useState(false)

  const targetElement = target as MutableRefObject<Element>

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const leftSideInViewport = entry.boundingClientRect.left >= 0
        const rightSideInViewport =
          entry.boundingClientRect.right <= window.innerWidth

        setIsInHorizontalViewPort(leftSideInViewport && rightSideInViewport)
      },
      {
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      },
    )

    targetElement.current && observer.observe(targetElement.current)

    return () =>
      targetElement.current && observer.unobserve(targetElement.current)
  }, [targetElement])

  return { isInHorizontalViewPort }
}
