import Facebook from './sources/Facebook.svg'
import Instagram from './sources/Instagram.svg'
import ArrowDown from './sources/ArrowDown.svg'
import Cross from './sources/Cross.svg'
import ArrowDownNoLine from './sources/ArrowDownNoLine.svg'
import Menu from './sources/Menu.svg'
import Logo from './sources/Logo.svg'
import Ellipse from './sources/Ellipse.svg'
import FilledEllipse from './sources/FilledEllipse.svg'
import Dragonfly from './sources/dragonfly.svg'
import Noise1 from './sources/noise_1.svg'
import Noise2 from './sources/noise_2.svg'
import Noise3 from './sources/noise_3.svg'
import Noise4 from './sources/noise_4.svg'
import OtherKitchenNoise1 from './sources/other_kitchen_noise_1.svg'
import OtherKitchenNoise2 from './sources/other_kitchen_noise_2.svg'
import AnimatedLine1 from './sources/animated_line_1.svg'
import AnimatedLine2 from './sources/animated_line_2.svg'
import MenuLine from './sources/menu_line.svg'
import Success from './sources/success.svg'
import Error from './sources/error.svg'
import ArrowUp from './sources/arrow_up.svg'
import sprite from './sources/sprite.svg'
import Close from './sources/close.svg'



export const Svg = {
  Facebook,
  Instagram,
  ArrowDown,
  Cross,
  ArrowDownNoLine,
  Menu,
  Logo,
  Ellipse,
  FilledEllipse,
  Dragonfly,
  Noise1,
  Noise2,
  Noise3,
  Noise4,
  OtherKitchenNoise1,
  OtherKitchenNoise2,
  AnimatedLine1,
  AnimatedLine2,
  MenuLine,
  Success,
  Error,
  ArrowUp, 
  sprite,
  Close,
}
