import BookBg from '././sources/bookBg.png'
import BgNew from './sources/bgNew.jpeg'
import AboutUs1 from './sources/about_us_1.png'
import AboutUs2 from './sources/about_us_2.png'
import Enjoy1 from './sources/enjoy_1.png'
import Enjoy2 from './sources/enjoy_2.png'
import Enjoy3 from './sources/enjoy_3.png'
import Enjoy4 from './sources/enjoy_5.png'
import Enjoy5 from './sources/enjoy_6.png'
import Enjoy6 from './sources/enjoy_7.png'
import GeneralMenu from './sources/general_menu.png'
import OurKitchen from './sources/our_kitchen.png'
import Libraria from './sources/libraria.png'
import MonsPins from './sources/mons_pins.png'
import ParaDjanow from './sources/para_djanow.png'
import Centaur from './sources/centaur.png'
import Swiper from './sources/swiper.png'
import Swiper1 from './sources/swiper_1.png'
import Noise1 from './sources/noise1.png'
import Noise2 from './sources/noise2.png'
import Noise3 from './sources/noise3.png'
import Noise4 from './sources/noise4.png'
import ModalBg from './sources/modal_bg.png'
import App from './sources/app_store.png'
import Google from './sources/google_play.png'




export const Png = {
  BookBg,
  BgNew,
  AboutUs1,
  AboutUs2,
  Enjoy1,
  Enjoy2,
  Enjoy3,
  Enjoy4,
  Enjoy5,
  Enjoy6,
  GeneralMenu,
  OurKitchen,
  Libraria,
  Centaur,
  MonsPins,
  ParaDjanow,
  Swiper,
  Swiper1,
  Noise1,
  Noise2,
  Noise3,
  Noise4, 
  ModalBg,
  App,
  Google
  }
