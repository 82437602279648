import { useResolution } from 'common/hooks'
import { Styled } from 'components/Styled'
import { Fade } from 'react-awesome-reveal'
import { Component } from './components'
import { StyledFooter, StyledFooterMenu } from './styled'

export const Footer = () => {
  const { isMinScreen1000 } = useResolution()

  return (
    <>
      <Styled.Hr />
      <Fade duration={3000} triggerOnce delay={1000}>
      <StyledFooter>
          <Component.BrandEntities />
          <StyledFooterMenu>
            <Component.General />
            <Component.Menu />
          </StyledFooterMenu>
          {isMinScreen1000 && <Component.UpButton />}
        </StyledFooter>
      </Fade>
    </>
  )
}
