import { EColors } from 'common/styles'
import styled from 'styled-components'

export const Container = styled.header`
  width: 100%;
  padding: 32px 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${EColors.bg_light};

  @media screen and (max-width: 550px) {
    padding: 16px 0;

    img {
      width: 85px;
    }
  }
`
