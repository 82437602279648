import { EColors } from 'common/styles'
import styled, { css } from 'styled-components'
import { TButton } from './types'

export const Button = styled.button<TButton>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 100px;
  cursor: pointer;

  background-color: transparent;
  border: 1px solid ${({ pallete }) => pallete.theme};

  transition: all 0.2s ease;

  img {
    width: 25%;
    filter: ${({ pallete }) => pallete.filter_theme};
    transition: all 0.2s ease;
  }

  ${({ disabled, pallete }) =>
    !disabled &&
    css`
      &:hover {
        background-color: ${pallete.theme};

        img {
          filter: ${pallete.active_arrow_color};
        }
      }
    `}

  ${({ disabled, pallete }) =>
    !!disabled &&
    css`
      cursor: unset;

      border-color: ${pallete.disabled};

      img {
        filter: ${pallete.filter_disabled};
      }
    `}



    ${({ position }) => {
    if (position === 'top') {
      return css`
        transform: rotateZ(180deg);
      `
    }

    if (position === 'left') {
      return css`
        transform: rotateZ(90deg);
      `
    }

    if (position === 'right') {
      return css`
        transform: rotateZ(-90deg);
      `
    }

    return css``
  }}
`
