import { EColors } from 'common/styles'
import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  background-color: ${EColors.bg_dark};
  position: relative;
  overflow: hidden;
  height: 1014px;

  @media screen and (max-width: 1000px) {
    height: 700px;
  }

  @media screen and (max-width: 550px) {
    height: 620px;
  }
`
