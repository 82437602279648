import { EColors, EFont } from "common/styles";
import { FONT } from "common/utils";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 168px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 999px) {
    height: 46px;
    margin-top: 12px;
  }

  @media screen and (max-width: 345px) {
    margin-top: 35px;
  }
`;

export const FranchiseWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1258px;
  position: relative;
  opacity: 0;
  transform: translateY(-100%);
  animation: appearence 0.5s linear 0.3s forwards;
  @keyframes appearence {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
export const FranchiseText = styled.span`
  ${FONT({
    size: "168px",
    color: EColors.neutral_500,
    weight: "600",
    family: EFont.Playfair_Display,
  })}
  line-height: 168px;

  opacity: 0.08;

  @media screen and (max-width: 1400px) {
    font-size: 130px;
    line-height: 130px;
  }

  @media screen and (max-width: 999px) {
    font-size: 90px;
    line-height: 90px;
  }

  @media screen and (max-width: 700px) {
    font-size: 60px;
    line-height: 60px;
  }

  @media screen and (max-width: 550px) {
    font-size: 46px;
    line-height: 46px;
  }
`;
export const TextAbsoluteWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextRelativeWrapper = styled.div`
  position: relative;
`;

export const Dragonfly = styled.img`
  position: absolute;
  width: 91px;
  top: -37px;
  right: -35px;
  z-index: -1;
  transform: rotateY(180deg);

  @media screen and (max-width: 999px) {
    width: 74px;
    top: 180px;
    right: -90px;
  }

  @media screen and (max-width: 450px) {
    right: -75px;
    top: 200px;
  }
`;
