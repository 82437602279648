import { useResolution } from "common/hooks"
import { LoadingApps } from "modules/LoadingApps"
import {  StyledLoadingWrapper } from "./styled"

export const LoadingWrapperApps = () => {
const {isMinScreen1000}=useResolution()
    return (
        <StyledLoadingWrapper>
            <LoadingApps direction={!isMinScreen1000?"row":"column" } />
        </StyledLoadingWrapper>
    )
}