
export const baseURL = 'https://production.api.restaron.kitg.com.ua';

export type TContent = {
    value: string,
    lang: string
}

export type TWorkingTime = {
    _id: string,
    day: TContent[], 
    start: string,
    end: string,
}
export type TLanding = {
    booking?: {
        photos: string []
    },
    contacts?: {
        email: string
    },
    offlineMenu?: null,
    _id: string,
    title?: TContent[],
    description: TContent[],
    address: TContent[],
    location?: {
        coordinates: number[],
        type: string
    },
    mainImage: string,
    mainSlider: string[],
    headerPhoto: string,
    headerColor?: string,
    workingTime: TWorkingTime[],
    slug: string,
    countGuests?: number,
    countDishes?: number,
    countBottles?: number,
    restaurant: string,
    updatedAt: string,
    metaDescription?: string,
    metaKeywords?: string[]
}



export const initialLanding = {
    booking: {
        photos: [""]
    },
    contacts: {
        email: ''
    },
    offlineMenu: null,
    _id: '',
    title: [{
        value: '',
        lang: ''
    },
    {
        value: '',
        lang: ''
    }],
    description: [{
        value: '',
        lang: ''
    },
    {
        value: '',
        lang: ''
    }],
    address: [{
        value: '',
        lang: ''
    },
    {
        value: '',
        lang: ''
    }],
    location: {
        coordinates: [],
        type: ''
    },
    mainImage: '',
    mainSlider: [''],
    headerPhoto: '',
    headerColor: '',
    workingTime: [
        {
            day: [
                { value: "", lang: "uk" },
                { value: "", lang: "en" }
            ],
            start: "",
            end: "",
            _id: ""
        }],
    slug: '',
    countGuests: 0,
    countDishes: 0,
    countBottles: 0,
    restaurant: '',
    updatedAt: '',
    metaDescription: '',
    metaKeywords: ['']
}

export const initialPolitics = [
    { value: "", lang: "uk" },
    { value: "", lang: "en" }
]


export type TPolitics = TContent[]
