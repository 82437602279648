import { Config } from 'common/config'
import { Component } from './components'
import { Container } from './styled'

export const OtherRestaurants = () => {
  return (
    <>
      <Container id={Config.Section.anotherRestaurants}>
          <Component.Noise />
          <Component.Slider />
      </Container>
    </>
  )
}
