import { Svg } from 'common/assets'
import { Comopnent } from './components'
import { Container } from './styled'

export const Header = () => {
  return (
    <>
      <Container>
        <img src={Svg.Logo} />
        <Comopnent.RightMenu />
      </Container>
    </>
  )
}
