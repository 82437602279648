import { EColors } from 'common/styles'
import { Styled } from 'components/Styled'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

type CopyRightProps = {
  style?: CSSProperties
}

export const CopyRight = ({ ...props }: CopyRightProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Styled.PBody2 {...props} color={EColors.neutral_600}>
        {t('copy.right')}
      </Styled.PBody2>
    </>
  )
}
