import { EColors } from 'common/styles'
import { Styled } from 'components/Styled'
import { GeneralMenu } from 'modules/GeneralMenu'
import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  z-index: 12;
  width: 100%;
  height: calc(100vh - 136px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
   @media screen and (max-width: 550px) {
    height: calc(100vh - 80px);
  }
`

export const Link = styled.a`
  text-decoration: none;
`

export const Item = styled(Styled.H1)`
  color: ${EColors.primary_100};
  transition: color 0.2s ease;
  cursor: pointer;

  &:hover {
    color: ${EColors.primary_500};
  }
`

export const StyledGeneralMenu = styled(GeneralMenu)`
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    gap: 14px;
  }
`