import { Png } from 'common/assets'
import { EColors } from 'common/styles'
import styled from 'styled-components'

export const Container = styled.section`
  background-color: ${EColors.bg_light};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContentWrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 140px 72px;

  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 1000px) {
    padding: 0px 72px;
    padding-bottom: 60px;
  }

  @media screen and (max-width: 550px) {
    padding: 0px 16px;
    padding-bottom: 60px;
  }
`

export const KitchenAboutImage = styled.img.attrs({ src: Png.OurKitchen })`
  width: 100%;
  max-width: 636px;
`
export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  align-items: flex-start;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`

export const LeftContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 48px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    align-items: center;
    gap: 20px;
  }
`
