import { Context } from 'common/contexts'
import { GlobalStyles } from 'common/styles'
import { useEffect } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Landing } from 'views'

export const App = () => {
  const { t } = useTranslation()
  const lang = localStorage.getItem("Localization") ?? "uk";
  useEffect(() => { document.title = t('title-head') }, [lang]);
  
  return (
    <>
      <Context>
        <GlobalStyles />
        <Landing />
      </Context>
    </>
  )
}

export default withTranslation()(App)
