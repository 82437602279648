import { Styled } from 'components/Styled'
import Slider from 'react-slick'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  padding-top: 140px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1000px) {
    padding-top: 60px;
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 1439px;
`

export const Title = styled(Styled.H2)`
  margin-left: 72px;
  @media screen and (max-width: 767px) {
    margin-left: 16px;
  }
`

export const StyledSlider = styled(Slider)`
  margin-top: 48px;

  @media screen and (max-width: 1000px) {
    margin-top: 24px;
  }
`

export const Slide = styled.a`
  height: 617px;
  margin-left: 40px;

  img {
    height: 553px;
  }

  @media screen and (max-width: 1000px) {
    height: 445px;
    margin-left: 20.5px;
    img {
      height: 401px;
    }
  }
`

export const RightButtonContainer = styled.div`
  position: absolute;

  bottom: 105px;
  right: 5%;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`

export const LeftButtonContainer = styled(RightButtonContainer)`
  left: 5%;
  right: none;
`
