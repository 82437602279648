import { Styled } from 'components/Styled'
import styled from 'styled-components'

export const TranslationButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 550px) {
    ${Styled.PBody1} {
      font-size: 14px;
    }
  }
`
