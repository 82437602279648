import { Png, Svg } from "common/assets"
import { Fade } from "react-awesome-reveal"
import { useTranslation } from "react-i18next"
import { StyledBg, StyledButtonLoad, StyledCloseButton, StyledContentWrapper, StyledImageWrapper, StyledModalLoad, StyledText, StyledTitle } from "./styled"
import { TModalProps } from "./types"


export const ModalLoad = ({ setModal }: TModalProps) => {
    const { t } = useTranslation()
    const handleClickClose = () => {
        setModal(false);
    }
    const handleClick = () => {
        if (navigator.userAgent.match(/IPhone|IPad|IPod/i)) {
            window.open('https://apps.apple.com/us/app/restaron/id6447766070')
        } else {
            window.open('https://play.google.com/store/apps/details?id=com.kitapp.restaron') 
        }
        setModal(false)
       
    }
    return (
        <StyledModalLoad>
            <StyledCloseButton type="button" onClick={handleClickClose}>
                <img src={Svg.Close} alt="icon close" />
            </StyledCloseButton>
            <StyledImageWrapper>
                <img src={Png.ModalBg} loading="lazy" />
                <StyledBg> &nbsp;</StyledBg>
            </StyledImageWrapper>
            <StyledContentWrapper>
                <StyledTitle>{t('title.load')}</StyledTitle>
                <StyledText>{t('text.load')}</StyledText>
            </StyledContentWrapper>
            <StyledButtonLoad type="button" onClick={handleClick}>{t('text.load.button')}</StyledButtonLoad>
            </StyledModalLoad>
    )
}