import { Svg } from "common/assets"
import { BookContext, EBookStatus } from "common/contexts/Book"
import { useContext, useEffect, useState } from "react"
import { Fade } from "react-awesome-reveal"
import { useTranslation } from "react-i18next"
import { Container, FormContainer, StatusImage, TextInfo } from "../Error/styled"
import { Button } from "components/buttons"

export const  Busy=()=>{
    const { t } = useTranslation()
    const { setStatus } = useContext(BookContext)
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
      setTimeout(() => {
        setIsVisible(true)
      }, 0)
    }, [isVisible])

    const onBackClick = () => {
      setStatus(EBookStatus.book)
    }
    return (
      <>
        <Container>
          <FormContainer style={{ opacity: isVisible ? 1 : 0 }}>
            <Fade triggerOnce>
              <StatusImage src={Svg.Error} />
              <TextInfo>{t('book.busy.text')}</TextInfo>
              <Button.Ellipse onClick={onBackClick}>
                {t('try.again')}
              </Button.Ellipse>
            </Fade>
          </FormContainer>
        </Container>
      </>
    )
}