import styled from "styled-components";

export const StyledAppWrapper = styled.div<{ direction?: string }>`
display: flex;
flex-direction:${({ direction }) => direction ? `${direction}` : "row"};
gap:16px;
align-items:center;
@media screen and (min-width: 1000px) and (max-width:1350px) {
  flex-direction:${({ direction }) => direction ? `${direction}` : "column"};
};
@media screen and (max-width: 767px) {
  display:none;
}
`
export const LinkApps = styled.a`
text-decoration:none;
display: inline-block;
& img{
  width:120px;
height:36px;
}

@media screen and (min-width: 1000px) and (max-width:1220px) {
  & img{
  max-width:120px;
  width:9.3vw;
  height: auto;
  }
}

`