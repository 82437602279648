import { Svg } from "common/assets"
import { ButtonLoading, ImageLoading, SpanText } from "./styled"

export const LoadingButton = ({ src, handleClick,text }:{ src: string, handleClick: () => void,text:string}) => {
    return (
        <ButtonLoading type="button" onClick={handleClick}>
            <ImageLoading><use href={src}/></ImageLoading> 
            <SpanText>{text}</SpanText>
        </ButtonLoading>
    )
}