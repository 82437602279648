import { Svg } from 'common/assets'
import { EllipseButtonPallets } from './pallets'
import {
  ButtonContainer,
  ContantContainer,
  EllipseButton,
  FilledEllipse,
} from './styled'
import { EllipseProps } from './types'

export const Ellipse = ({
  disabled = false,
  width = '244px',
  children,
  colorPallete = EllipseButtonPallets.default,
  ...props
}: EllipseProps) => {
  return (
    <>
      <ButtonContainer
        {...props}
        disabled={disabled}
        width={width}
        colorPallete={colorPallete}>
        <EllipseButton
          src={Svg.Ellipse}
          disabled={disabled}
          colorPallete={colorPallete}
        />
        <FilledEllipse
          src={Svg.FilledEllipse}
          disabled={disabled}
          colorPallete={colorPallete}
        />
        <ContantContainer>{children}</ContantContainer>
      </ButtonContainer>
    </>
  )
}
