import { ButtonText } from './styled'
import { TextProps } from './types'

export const Text = ({ children, disabled = false, ...props }: TextProps) => {
  return (
    <>
      <ButtonText disabled={disabled} {...props}>
        {children}
      </ButtonText>
    </>
  )
}
