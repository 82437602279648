import { FlexWrapper } from 'components/Styled/Styled'
import styled from 'styled-components'

export const Container = styled(FlexWrapper)`
  display: flex;
  gap: 30px;
  opacity: 0;
  transform: translateY(-100%);
  animation: appearence 0.3s linear 0.1s forwards;
  @keyframes appearence {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
 @media screen and (max-width: 1199px) {
    gap: 16px;
  }
  @media screen and (max-width: 550px) {
    gap: 28px;
  }
`
