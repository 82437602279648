import { ModalLoad } from './ModalLoad'
import { StyledModalWrapper } from './styled'
import {  useState,useEffect } from 'react'


export const ModalAppLoad = () => {
    const [modal, setModal] = useState(true);

  useEffect(() => {
    modal ? document.body.classList.add("hidden") : document.body.classList.remove("hidden");
    modal ? document.getElementById("backdrop")?.classList.remove("is-hidden") : document.getElementById("backdrop")?.classList.add("is-hidden"); 
  },[modal])
   
    return (
      <StyledModalWrapper id="backdrop" >
        <ModalLoad setModal={setModal} />
    </StyledModalWrapper>
  )
}
