import { useState, useEffect, MutableRefObject } from 'react'
import { UseIntersectionProps } from './types'

export const useIntersection = ({
  element,
  options = {
    rootMargin: '0px',
    threshold: 0,
  },
}: UseIntersectionProps) => {
  const [isVisible, setState] = useState(false)
  const targetElement = element as MutableRefObject<Element>

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setState(entry.isIntersecting)
    }, options)

    targetElement.current && observer.observe(targetElement.current)

    return () =>
      targetElement.current && observer.unobserve(targetElement.current)
  }, [targetElement])

  return isVisible
}
