export const General = {
  generalPolicyPrivacy: '#',
  policyPrivacy: '#',
  instagram: 'https://www.instagram.com/babo.gardens/',
  facebook: 'https://www.facebook.com/babogardens/',
  googleApiKey: 'AIzaSyAfq0_75Gl5XXb9En8dlz7LdPQhftS8XH0',
  parajanov: 'https://parajanov.com.ua/',
  monspius:'https://monspius.com.ua/',
  libraria: 'https://libraria.com.ua/',
  centaura:'https://centaura.com.ua/',
  phone: '(067) 000-15-26',
}
