import { Svg } from 'common/assets'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Dragonfly,
  FranchiseText,
  FranchiseWrapper,
  TextAbsoluteWrapper,
  TextRelativeWrapper,
} from './styled'

export const Franchise = () => {
  const { t } = useTranslation()

  return (
    <>
      <Container>
        <FranchiseWrapper>
          <TextAbsoluteWrapper>
            <TextRelativeWrapper>
              {/* Franchise text */}
              <FranchiseText>{t('franchise')}</FranchiseText>

              {/* Dragonfly */}
              <Dragonfly src={Svg.Dragonfly} />
            </TextRelativeWrapper>
          </TextAbsoluteWrapper>
        </FranchiseWrapper>
      </Container>
    </>
  )
}
