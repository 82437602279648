import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 999px) {
    gap: 20px;
  }
`

export const ContentWarpper = styled.div`
  width: 100%;
  display: flex;
  gap: 30px;
  align-items: baseline;

  @media screen and (max-width: 999px) {
    flex-direction: column;
    gap: 4px;
  }
`

export const TitleWrapper = styled.div`
  width: 50%;
  display: flex;
  gap: 15px;
  align-items: baseline;
  justify-content: flex-start;

  @media screen and (max-width:999px) {
    width: 100%;
  }
`

export const InfoWrapper = styled.div`
  width: 50%;

  display: flex;
  gap: 15px;
  align-items: flex-end;
  justify-content: flex-start;

  @media screen and (max-width: 999px) {
    width: 100%;
  }
`
