import { EColors } from 'common/styles'
import { TEllipsePallete } from './types'

type TEllipesePalleteKeys = 'default' | 'white'

type TEllipsePalletes = Record<TEllipesePalleteKeys, TEllipsePallete>

export const EllipseButtonPallets: TEllipsePalletes = {
  default: {
    mainButton: EColors.filter_primary_500,
    hoverButton: EColors.filter_primary_600,
    activeButton: EColors.filter_primary_600,
    mainText: EColors.primary_500,
    hoverText: EColors.white,
    activeText: EColors.white,
  },
  white: {
    mainButton: EColors.filter_white,
    hoverButton: EColors.filter_white,
    activeButton: EColors.filter_white,
    mainText: EColors.white,
    hoverText: EColors.black,
    activeText: EColors.black,
  }
}
