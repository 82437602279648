import { BookContext } from 'common/contexts/Book'
import { LandingContext } from 'common/contexts/Landing/Landing'
import { Button } from 'components/buttons'
import { baseURL } from 'models'
import { CSSProperties, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from './styled'

type GeneralMenuProps = {
  hideOurMenu?: boolean
  hideBookTable?: boolean
  hideDelivery?: boolean
  style?: CSSProperties
}

export const GeneralMenu = ({
  hideOurMenu,
  hideBookTable,
  hideDelivery,
  ...props
}: GeneralMenuProps) => {
  const { t } = useTranslation()
  const { isOpen, setIsOpen } = useContext(BookContext)
  const { landing } = useContext(LandingContext)
  const lang = localStorage.getItem("Localization") ?? "uk";

  const handleOpenMenu = () => {
    if (landing.offlineMenu === null) {
      return
    }
    window.open(`${baseURL}/${landing?.offlineMenu}`, '_blank')
  }

  const onBookTable = () => {
    window.location.href = '/?modal=reservation'
    setIsOpen(true)
  }

  const handleOpenDelivery = () => {
    window.open(
      lang === "en" ? 'https://restaron.ua/en/restaurant/babo-gardens' : 'https://restaron.ua/restaurant/babo-gardens',
      '_blank',
    )
  }

  return (
    <>
      <Container {...props}>
        {!hideOurMenu && (
          <Button.Text onClick={handleOpenMenu}>{t('our.menu')}</Button.Text>
        )}
        {!hideBookTable && (
          <Button.Ellipse onClick={onBookTable}>
            {t('book.table')}
          </Button.Ellipse>
        )}
        {!hideDelivery && (
          <Button.Text onClick={handleOpenDelivery}>
            {t('delivery')}
          </Button.Text>
        )}
      </Container>
    </>
  )
}
