import { Modal } from 'modules/modals'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react'

type MenuProviderProps = {
  children: ReactNode
}

type MenuContextProps = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const MenuContext = createContext<MenuContextProps>({
  isOpen: false,
  setIsOpen: () => {},
})

export const MenuProvider = ({ children }: MenuProviderProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const value = {
    isOpen,
    setIsOpen,
  }

  return (
    <MenuContext.Provider value={value}>
      {children}
      {isOpen && <Modal.Menu />}
    </MenuContext.Provider>
  )
}
