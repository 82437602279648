import { SmoothScroll } from 'components/SmoothScroll'
import { ReactNode } from 'react'

type SmoothScrollProviderProps = {
  children: ReactNode
}

export const SmoothScrollProvider = ({
  children,
}: SmoothScrollProviderProps) => {
  return (
    <>
      <>{children}</>
    </>
  )
}
