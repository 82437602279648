import { CopyRight } from "components/CopyRight";
import styled from "styled-components";

export const FlexWrapperBrand = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    align-items: center;
  }
`;

export const StyledCopyRight = styled(CopyRight)`
  display: flex;
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;